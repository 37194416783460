import 'popper.js';
import 'bootstrap/util';
import 'bootstrap/collapse';
import 'bootstrap/modal';
import toast from 'izitoast';
import 'web-animations-js';
import Muuri from 'muuri';
import './_prototypes/parsley';
import flatpickr from 'flatpickr';
import moment from 'moment';
import normalizeFspUrl from './_util/normalizeFspUrl';

moment.suppressDeprecationWarnings = true;

const $template = $('#template');
const $promotions = $('#promotions .grid-container');
const $quickAccessCodeForm = $('#quick-access-code-form');
// const $quickSearch = $('#quick-search');
// const $customSearchFilters = $('.__custom_search_filter__');
const $promoModalContainer = $('#promo-modal-container');
// const $manufacturers = $('#manufacturers');
const $emailOffersForm = $('#email-offers-form');
const $emailOffersFormSubmit = $emailOffersForm.find('button[type="submit"]');
const $thanks = $('#emailOffersThanks');
const $emailOffersError = $('#emailOffersError');
const BASE = $('base').attr('href');
const FSPBASE = normalizeFspUrl($template.data('fspbase'));
const FSPEVENTURL = `${FSPBASE}/api/events`;


const screenWidth = window.innerWidth;

// On small screens, make sure the sign in button goes to the app version of the fspromos4u site
if (screenWidth < 600) {
    $('.signin-btn').attr('href', 'https://app.fspromos4u.com/login');
}


/**
 * Item grid
 */
const grids = [];
if ($promotions.length) {
    $promotions.each(function () {
        const $gridContainer = $(this);
        const m = new Muuri($gridContainer.get(0), {
            items: $gridContainer.get(0).querySelectorAll('.grid-item'),
            // Custom layout to center items and force them all to be the same height
            layout(items, gridWidth) {
                gridWidth = $gridContainer.parent().width();
                const layout = {
                    slots: [],
                    width: 0,
                    height: 0,
                    setWidth: true,
                    setHeight: true,
                };
                if (!items.length) return layout;
                const itemHeight = Math.max(...items.map(item => {
                    const margin = item.getMargin();
                    return item.getHeight() + margin.top + margin.bottom;
                }));
                const itemMargin = items[0].getMargin();
                const itemWidth = items[0].getWidth() + itemMargin.left + itemMargin.right;
                const howManyColumns = Math.floor(gridWidth / itemWidth);
                const howManyRows = Math.ceil(items.length / howManyColumns);

                items.forEach((item, i) => {
                    const image = item.getElement().querySelector('img');
                    if (image && image.complete) {
                        const margins = item.getMargin();
                        item.getElement().style.height = `${itemHeight - margins.top - margins.bottom}px`;
                        item._refreshDimensions();
                    }
                    const whatRow = Math.floor(i / howManyColumns);
                    const whatColumn = i % howManyColumns;
                    const offset = (gridWidth - (howManyColumns * itemWidth)) / 2;
                    const y = whatRow * itemHeight;
                    const x = (whatColumn * itemWidth) + offset;
                    layout.slots.push(x, y);
                });

                layout.width = howManyColumns * itemWidth;
                layout.height = howManyRows * itemHeight;

                return layout;
            },
        });

        $(this)
            .find('img')
            .on('load', () => {
                if ('refreshItems' in m) {
                    m.refreshItems().layout();
                }
            });

        grids.push(m);
    });
}

/**
 * Quick Access Code button
 */
$quickAccessCodeForm
    // Populate the 'ga-event-label' for ga event tracking
    .on('input', function (e) {
        $quickAccessCodeForm.attr('ga-event-label', $(e.target).val());
    })
    // Position the invalid message on top of the input
    .parsley({
        errorsWrapper: '<span class="invalid-feedback position-absolute" style="top: -30px; left: 0;"></span>',
        successClass: '',
    })
    .on('form:submit', function () {
        const formdata = $(this.element).serializeArray().reduce((obj, member) => {
            obj[member.name] = member.value;
            return obj;
        }, {});
        // EI 360 redirect needed on homepage PR766385989
        if (formdata.offercode.trim().toLowerCase().includes('wyndham360rebate')) {
            window.open('https://www.reply4info.com/ei/wyndham-360-rebate');
            return false;
        }
        window.open(`${FSPBASE}?offercode=${formdata.offercode}`);
        return false;
    });


/**
 * Track download function
 */
$promoModalContainer.on('click', '.btn-download', function () {
    $.post({
        url: FSPEVENTURL,
        data: {
            loginsid: null,
            eventtype: 'download',
            occurpage: window.location.href,
            clientcode: $(this).data('clientcode'),
            offercode: $(this).data('offercode'),
            ip: $template.data('ip'),
            adduser: 'FSP APP',
            edituser: 'FSP APP',
        },
    });
});

/**
 * View promo
 */
$('.grid-item')
    .on('click', function () {
        const promoid = $(this).data('promoid');
        $.get(`${BASE}/${promoid}`)
            .done(html => {
                $promoModalContainer
                    .html(html)
                    .find('#promo-modal')
                    .modal();
            });
    });

/**
 * Email offers
 */
$emailOffersForm
    .parsley()
    .on('form:submit', function () {
        const $form = $(this.element);
        const emailOffersFormSubmitWidth = $emailOffersFormSubmit.width();
        const emailOffersFormSubmitHtml = $emailOffersFormSubmit.html();
        const method = $form.attr('method');
        const action = $form.attr('action');
        const formdata = $(this.element).serializeArray().reduce((obj, member) => {
            obj[member.name] = member.value;
            return obj;
        }, {});

        // If there's a checkbox, set it to a boolean value
        $form.find(':checkbox[name]').each(function () {
            formdata[this.name] = this.checked;
        });

        $emailOffersFormSubmit
            .prop('disabled', true)
            .attr('aria-disabled', true)
            .width(emailOffersFormSubmitWidth)
            .html('<i class="fas fa-spinner fa-pulse fa-fw"></i>');

        $.ajax({
            url: action,
            method,
            data: formdata,
        })
            .done(response => {
                if (response.success) {
                    $emailOffersForm
                        .hide()
                        .parents('.modal')
                        .find('.modal-header')
                        .hide();
                    $thanks.removeClass('d-none');
                }
            })
            .fail((xhr, stat, err) => {
                $emailOffersForm.show();
                $thanks.addClass('d-none');
                $emailOffersFormSubmit
                    .prop('disabled', false)
                    .attr('aria-disabled', false)
                    .width(emailOffersFormSubmitWidth)
                    .html(emailOffersFormSubmitHtml);
                $emailOffersError
                    .removeClass('d-none')
                    .html('An error has occurred. Please try again or contact support');
            });

        return false;
    });

// =====================================================================================================================
// Showing install popup here. After closing it sets a cookie for a month to prevent showing it again.
// =====================================================================================================================
$(document).ready(function () {
    // Optional: use ?popup=true to delete the alreadyDismissedPopup cookie if it exists
    const queryString = new URLSearchParams(window.location.search);
    if (queryString.has('popup')) {
        document.cookie = 'alreadyDismissedPopup=;max-age=0';
    }

    // Check for cookie "alreadyDismissedPopup" to know if you should show the popup or not
    const alreadyDismissedPopup = document.cookie.split(';').some(item => item.trim().startsWith('alreadyDismissedPopup='));
    if (alreadyDismissedPopup) return false;

    // Show the popup
    toast.show({
        // theme: 'light',
        title: 'Install FSPromos4u',
        message: 'Click here to learn how to install FSPromos4u on your device.',
        position: 'bottomCenter', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter
        progressBarColor: 'rgb(67, 116, 150)',
        backgroundColor: 'rgb(238, 238, 238)',
        timeout: +(1000 * 10), // 10 seconds
        resetOnHover: true,
        closeButton: true,
        buttons: [
            ['<button>IOS</button>', function (instance, toast) {
                window.open('./install/ios', '_blank');
            }, false], // true to focus
            ['<button>Android</button>', function (instance, toast) {
                window.open('./install/android', '_blank');
            }, false], // true to focus

        ],
        onClosed: function () {
            // Set a cookie to prevent showing the popup again until it expires (age = 86400 * days)
            document.cookie = 'alreadyDismissedPopup=true;max-age=2592000}';
        },
    });
});

// =====================================================================================================================
// Email Reminder Modal - below here
// This is a modal on a modal and it can be a little confusing but I will add some good comments to help.
// I also tried putting this into a separate js file but it kept causing other issues and never worked.
// =====================================================================================================================

// Variables for the email reminder modal
let datesSelected;
const flatpickrConfig = {
    mode: 'multiple',
    dateFormat: 'm/d/Y',
    allowInput: false,
    minDate: moment(new Date()).add(1, 'days').format('MM/DD/YYYY'), // First available date is tomorrow
};
// Finds out what the day before the promo enddate is
function getTheDayBeforePromoEnddate(enddate) {
    if (!enddate) return false;

    const enddateReminder = moment(enddate).subtract(1, 'days').format('MM/DD/YYYY');
    return enddateReminder;
}
// Uses a date value to create a date button and append it to the row
function createDateButton(date) {
    const newButton = `
        <button class="date-btn" type="button">${moment(date).format('MM/DD/YYYY')}</button>
    `;
    $('.date-group__left__dates-row').append(newButton);
}

/**
 * Opens the email reminder modal. (from the promo info modal)
 */
$('#promo-modal-container').on('click', '#email-reminder-btn', function (e) {
    // todo enable this on production when email reminder is live
    e.preventDefault();
    // const offercode = $(this).data('offercode'); // Maybe pass offercode somewhere? It's here if we need it.
    $('#email-reminder-modal-container').find('#email-reminder-modal').modal();
    // blur the modal behind
    $('#promo-modal').addClass('backdrop');

    // Prevent datepicker button from closing modal
    $('#datepicker').on('click', e => e.preventDefault());

    // Clear any old values when opening or re-opening the modal
    $('.date-group__left__dates-row').empty();
    datesSelected = [];

    // Initialize flatpickr as empty
    $('#datepicker').flatpickr(flatpickrConfig).clear();

    // If the promo has an enddate, set the reminder to 1 day before
    const enddateReminder = getTheDayBeforePromoEnddate($('#email-reminder-btn').data('enddate'));
    if (enddateReminder) {
        datesSelected.push(enddateReminder);
        $('#datepicker').flatpickr({
            ...flatpickrConfig,
            defaultDate: datesSelected,
        });
        createDateButton(enddateReminder);
    }

    // Make sure the submit button is enabled just in case
    $('#email-reminder-submit-btn').attr('disabled', false);

});


/**
 * Update the date buttons when a date is selected or deselected in the flatpickr
 */
$('#datepicker').on('change', function () {
    // Clear previous values
    $('.date-group__left__dates-row').empty();

    // Get the comma separated list of dates selected from flatpickr
    const dateString = $(this).val();

    // If there are no dates selected return out
    if (!dateString || !dateString.length) return false;

    // Build an array from the comma separated list of dates
    datesSelected = dateString.split(',');

    // Create an array of dates out of the array of date strings
    datesSelected = datesSelected.map(date => moment(date).format('MM/DD/YYYY'));

    // Create a date button for each date
    datesSelected.forEach((date, index) => {
        createDateButton(date);
    });
});


/**
 * Option to remove a selected date by clicking on the date button
 */
$('#email-reminder-modal').on('click', '.date-btn', function (e) {
    e.preventDefault();
    // Get the value of the button we are going to remove
    const dateValue = $(this).html();

    // Remove the date button from the row
    $(this).remove();

    // Remove the date from the array of selected dates and update flatpickr too
    datesSelected = datesSelected.filter(date => date.trim() !== dateValue);
    $('#datepicker').flatpickr({
        ...flatpickrConfig,
        defaultDate: datesSelected,
    });

    return false;
});

/**
 * Removes the blur on the promo modal when dismissing the email reminder modal
 * Also clears old values on the email reminder modal just to be safe
 */
$('#email-reminder-modal').on('hidden.bs.modal', function () {
    $('#promo-modal').removeClass('backdrop');
});

/**
 * Toggle between "Email" and "Text-Message" options. Also sets the correct input field as required.
 */
$('#email-reminder-modal-container').on('change', 'input[name="sendtype"]', function () {
    const sendType = $(this).val();
    if (sendType === 'email') {
        $('#phone').attr('hidden', true).attr('required', false);
        $('#email').attr('hidden', false).attr('required', true);
    } else {
        $('#phone').attr('hidden', false).attr('required', true);
        $('#email').attr('hidden', true).attr('required', false);
    }
});


/**
 *  Submitting the email reminder form
 */
$('#email-reminder-form')
    .on('submit', function () {
        // Disable the submit button to prevent double submissions
        $('#email-reminder-submit-btn').attr('disabled', true);

        if (!datesSelected || !datesSelected.length) {
            toast.warning({
                message: 'Please make sure to select at least 1 date.',
                position: 'bottomRight',
                timeout: 4000,
            });
            $('#email-reminder-submit-btn').attr('disabled', false);
            return false;
        }

        const formdata = {
            homedir: $(this).data('homedir') || 'N/A',
            clientcode: $(this).data('clientcode') || 'N/A',
            offercode: $('#email-reminder-btn').data('offercode'),
            sendtype: $('input[name="sendtype"]:checked').val(),
            datetosend: datesSelected,
            notetoself: $('#notetoself').val(),
        };
        formdata.sendto = (formdata.sendtype === 'email') ? $('#email').val() : $('#phone').val();


        $.ajax({
            type: 'POST',
            url: `./${formdata.homedir}/email-reminder`,
            data: {
                formdata,
            },
            success: function (response) {
                if (!response.success) {
                    toast.warning({
                        title: 'Warning',
                        message: 'Failed to save reminder. Please try again or contact support.',
                        position: 'bottomRight',
                        timeout: 4000,
                    });
                    $('#email-reminder-submit-btn').attr('disabled', false);
                    return false;
                }
                toast.success({
                    title: 'Success',
                    message: 'Your reminder was saved.',
                    position: 'bottomRight',
                    timeout: 4000,
                });
                // This should close the email reminder modal and remove the blur from the promo modal
                $('#email-reminder-modal-dismiss').trigger('click');
                return false;
            },
            error: function (err) {
                console.log(err);
                // This should hopefully never show but at least there will be something if it does.
                toast.error({
                    title: 'Error',
                    message: 'There was an internal error while saving your reminder. Try again later or contact support.',
                    position: 'bottomRight',
                    timeout: 4000,
                });
                $('#email-reminder-submit-btn').attr('disabled', false);
                return false;
            },
        });

        return false;
    });
